import { CommonModule, NgClass } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DateTimeAdapter } from '@fuse/components/datetime';
import { FuseConfirmationConfig } from '@fuse/services/confirmation/confirmation.types';
import { DetailDialogResponseType } from 'app/components/detail-dialog/detail-dialog.types';

@Component({
    selector: 'fuse-confirmation-dialog',
    templateUrl: './dialog.component.html',
    styles: [
        /* language=SCSS */
        `
            .fuse-confirmation-dialog-panel {
                @screen md {
                    @apply w-128;
                }

                .mat-mdc-dialog-container {

                    .mat-mdc-dialog-surface {
                        padding: 0 !important;
                    }
                }
            }
        `
    ],
    encapsulation: ViewEncapsulation.None,
    imports: [MatButtonModule, MatDialogModule, MatIconModule, NgClass]
})
export class FuseConfirmationDialogComponent
{    data = inject<FuseConfirmationConfig>(MAT_DIALOG_DATA);
    matDialogRef = inject<MatDialogRef<FuseConfirmationDialogComponent, DetailDialogResponseType>>(MatDialogRef);


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

}
